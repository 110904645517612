.nav_bar{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    backdrop-filter: blur(20px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.nav_menu {
    width: 100%;
    display: flex; /* Keep your existing flexbox layout */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

.nav_link {
    text-decoration: none;
    color: #333; /* Set the default color */
    transition: color 0.3s ease; /* Add a smooth transition effect */
  
    /* Optional: Adjust other styles as needed */
    margin: 0 10px;
}
  
.nav_link:hover {
    color: #005cbe; /* Set the color on hover */
}   

.nav-left {
    display: flex;
    flex: 60%; /* Set the width of the left section to 60% */
  }
  
.nav-right {
    display: flex;
    flex: 20%; /* Set the width of the right section to 20% */
    justify-content: flex-end; /* Align items to the end (right) */
}

.hamburger_container{
    display: none; /* Hidden by default */
    cursor: pointer;
    width: 50px;
    height: 50px;
}

.nav_close{
    font-size: 30px;
}

.hamburger {
    padding: 10px;
    width: 35px;
    height: auto;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {color: #005cbe}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropbtn {
    // background-color: #0550d2;
    background-color: #ffffff;
    // color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    font-family: 'Hedvig Letters Serif', serif;
}

@media screen and (max-width: 960px){
    .nav_bar{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        // z-index: 1000;
        // position: absolute;
        width: 100%;
        align-items: flex-start;
    }

    .hamburger_container {
        display: block; /* Show hamburger on small screens */
    }

    .nav_menu {
        display: none; /* Hide menu initially on small screens */
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #ffffff;
        z-index: 1000;
    }

    .nav_menu.open {
        display: flex; /* Show menu when hamburger is clicked */
        flex-direction: column; /* Stack the links vertically */
        justify-content: flex-start;
    }

    .nav_left{
        display: flex;
        flex-direction:column;
        width: 100%;
    }

    .nav_right{
        display: flex;
        flex-direction:column;
        width: 100%;
    }

    .dropbtn{
        margin-left: 10px;
        padding:0 0 10px 0;
    }

    .nav_link{
        padding-bottom: 10px;
    }

    .dropdown-content{
        position:static;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: none;
    }
}
    