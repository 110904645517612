
/* Container for the entire form */
#payment-form {
    max-width: 70vw;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
}

/* Style the payment button */
#payment-button {
    display: block;
    width: 100%;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #007bff; /* Your preferred button color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#payment-button:hover {
    background-color: #0056b3; /* Hover color */
}

/* Style the spinner */
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #007bff; /* Match button color */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

/* Style the payment message */
#payment-message {
    margin-top: 10px;
    padding: 10px;
    background-color: #dff0d8; /* Success message background color */
    border: 1px solid #4cae4c; /* Success message border color */
    border-radius: 5px;
    color: #4cae4c; /* Success message text color */
    font-weight: bold;
}

/* Define a simple CSS animation for the spinner */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/* Style the payment container */
.payment_status_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh; /* At least 70% of the viewport height */
    text-align: center;
  }
  
  /* Style the green checkmark with animation */
  .payment_checkmark {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #4caf50; /* Green color */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px; /* Adjust as needed */
    animation: bounce 1s ease 3; /* Add animation */
  }
  
  /* Style the h1 */
  .payment_status_container h1 {
    font-size: 24px;
  }
  
  /* Define a simple CSS animation for the checkmark */
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  

  /* Style the total amount display */
.payment_total_amount {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  color: #4caf50;
}

/* Style the payment container */
.payment_container {
  max-width: 800px; /* Adjust the max width as needed */
  margin: 0 auto;
  padding: 20px;
  min-height: 70vh;
}

/* Style the title */
.payment_title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center; /* Center the title text */
}

/* Style the payment section */
.payment_section {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  background-color: #f7f7f7;
}
