body,
html {
    font-family: 'Hedvig Letters Serif', serif;
    // font-family: 'Righteous', sans-serif;
    height: 100%;
    margin: 0; /* Remove default body margin */
    padding: 0; /* Remove default body padding */
    height: 100vh; /* Set full viewport height */
}

a {
    text-decoration: none;
    color: #000000;
}

.landing_container{
    background-image: url('./assets/img/landing_page_background.png'); /* Set the background image path */
    background-size: cover; /* Cover the entire viewport */
    background-position: center; /* Center the image */
    background-position-y: -40vh;
}

.logo {
    background-image: url('assets/img/logo_with_name.png');
    width: 300px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
}

.active {
    color: pink;
}

.landing_container {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    height: 100vh;
}

.landing_title {
    margin-top: 5px;
    margin-bottom: 10px;
}

.landing_logo_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
}

.landing_logo{
    width: 15vh;
    height: 15vh;
}

.landing_contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    backdrop-filter: blur(20px);
    padding: 30px;
    border-radius: 10%;
    // height: 100vh;
}

.landing_pdf_container {  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}

.landing_pdf_title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
}

.landing_pdf_download_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
  
.landing_pdf_logo {
    width: 10vh;
    height: 10vh;
}

.home_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner_container{
    display: flex;
    width:100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-image: url('./assets/img/landing_page_background.png');
    background-size: cover; /* Cover the entire container */
    background-repeat: no-repeat; /* Prevent repeating the image */
    background-position: center; 
}

.home_title {
    text-align: center;
    color: #ffffff; /* Set the text color */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
  
.home_title h1,
.home_title h2 {
    margin: 0;  
}

.home_button_container {
    margin-top: 20px;
}

.home_button_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home_button{
    background-color: #044aab;
    border: none;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    font-family: 'Hedvig Letters Serif', serif;
}

/* YourComponent.css */

.image-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

.partner_container{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
  
.partner-image {
    width: 100px; /* Adjust the width as needed */
    height: 100px; /* Adjust the height as needed */
    margin: 5px; /* Adjust the margin between images */
    transition: transform 0.3s ease-in-out;
}

.partner-image:hover {
    transform: translateY(-5px);
}
  


.footer {
    background-color: #f3f3f3;
    padding: 20px;
    display: flex;
    justify-content: space-around;
}

.footer-column {
    display: flex;
    flex-direction: column;
}

.footer-column h3 {
    margin: 0;
    margin-bottom: 10px;
}

.footer-column ul {
    list-style: none;
    padding: 0;
}

.footer-column ul li a {
    text-decoration: none;
    color: #000;
}

.footer-column p {
    margin: 0 0 10px 0;
    font-size: 0.9rem;
}

.footer-list{
    margin:0;
    padding: 4px 8px;
}

.footer-text{
    padding-left: 3px;
    font-size: 0.9rem;
}

.social-media-icon{
    width: 30px;
    height: 30px;
    margin: 3px 5px;
}

/* CompanySection.css */
.section_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.grey_section_container {
    // display: flex; /* Use Flexbox */
    // justify-content: space-between; /* Space out children evenly */
    // align-items: center; /* Align children vertically in the middle */
    background-color: #f3f3f3;
}

.horizontal_section_container {
    flex-direction: row;
}

.company_section_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.vertical_section_container {
    flex-direction: column;
}

.company_text_section, .company_image_section {
    flex: 1; /* Each child will take up equal space */
    /* If you want exactly 50%, use 'flex-basis: 50%' instead of 'flex: 1' */
}
  
.company_image {
    width: 100%; /* Adjust the width as needed */
    height: 100%; /* Adjust the height as needed */
    object-fit: cover;
}


/* ProcessSection.css */

.process-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
  
.process-step {
    text-align: center;
}

.arrow {
    font-size: 24px;
    margin: 0 20px;
}

img {
    width: 80px; /* Adjust the width of the icons */
    height: 80px; /* Adjust the height of the icons */
}
  

.pagination {
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
}

.page-item {
    margin: 0 5px;
}

.page-link {
    border: 1px solid #ddd;
    padding: 5px 10px;
    color: black;
    text-decoration: none;
}

.page-link:hover {
    background-color: #eee;
}


.filter {
    /* Styles for the filter component */
    border-radius: 10px;
    border: 1px solid #cdcdcd;
    padding: 5px;
    
}

.filter-title{
    font-size: 22px;
    font-weight: bold;
    margin:0;
    padding: 10px 5px;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.product-thumbnail {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    margin: 0 0 10px 10px ;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease-in-out;
    width: 230px; /* For two columns, adjust as needed */
    text-align: center;
    height:250px;
}

.product-thumbnail:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.product-thumbnail h4 {
    font-size: 1.1em;
    margin-bottom: 5px;
    color: #333;
}

.product-thumbnail p {
    font-size: 1em;
    color: #666;
    margin-bottom: 10px;
}

.quantity-controls {
    display: flex;
    align-items: center;
    justify-content: center;
}

.quantity_button {
    background-color: #f2f2f2;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 1em;
}

.quantity_button:hover {
    background-color: #e2e2e2;
}

.quantity_text {
    margin: 0 10px;
    font-size: 1em;
}

.filter-option {
    margin-top: 10px;
    text-align: left;
}

.size-selection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin:0;
    text-align: left;
}

.size-selection label,
.filter-option label {
    margin-right: 5px;
    font-size: 0.9em;
    color: #333;
    font-family: 'Hedvig Letters Serif', serif;
}

.size-selection select,
.filter-option select {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: white;
    font-size: 0.9em;
    color: #333;
    cursor: pointer;
    font-family: 'Hedvig Letters Serif', serif;
}

.filter-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/* Style to improve appearance on focus */
.size-selection select:focus {
    outline: none;
    border-color: #007bff;
}

.product-list-page {
    display: flex;
    width: 100%;
}

.filter-section {
    flex-basis: 20%; /* Takes up 20% of the page width */
    max-width: 20%; /* Ensure it doesn't grow beyond 20% */
}

.filter-button-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    margin:0;
    text-align: left;

}

.filter-button {
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.filter-button-cancel{
    padding: 10px;
    margin-top: 10px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.product-list-section {
    flex-grow: 1; /* Takes up the remaining space */
    padding: 10px;
}

.product-page {
    display: flex;
    width: 100%;
    padding: 20px;
    box-sizing: border  -box; /* Ensures padding doesn't affect overall width */
}

.product_list_filter_container{
    flex: 0 0 30%;
    max-width: 320px;
}

.product_list_list_container{
    flex: 1;
}

.filter-section {
    flex-basis: 20%; /* Filter section takes up 20% of the width */
    max-width: 20%; /* Ensures it doesn't grow beyond 20% */
    padding-right: 20px; /* Optional spacing between filter and product list */
}

.product-list-section {
    flex-grow: 1; /* Takes up the remaining space */
}


// .product-thumbnail {
//     width: 230px; /* For two columns, adjust as needed */
//     margin-bottom: 20px;
//     text-align: center;
//     height:250px;
//     // overflow: hidden;
// }

.product-thumbnail-title,
.product-thumbnail-price {
    margin: 0;
    padding: 2px 0;
}

// .product-thumbnail img {
//     width: 100%;
//     height: 120px;
//     object-fit: fill; /* Image will be scaled down to fit, maintaining aspect ratio */
//     flex-grow: 0; /* Prevent image from growing */
//     flex-shrink: 0;
//     margin-bottom: 0;
// }

.product-thumbnail-image-container{
    height: 120px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-thumbnail-info-container{
    height:130px;
    overflow: scale-down;
}

.product-thumbnail-price-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.product-thumbnail-cart-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;

}

/* Styles for Pagination */
.pagination {
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
    margin-top: 20px; /* Space between products and pagination */
}

.page-item {
    margin: 0 5px;
}

.page-link {
    border: 1px solid #ddd;
    padding: 5px 10px;
    color: black;
    text-decoration: none;
}

.page-link:hover, .page-link:focus {
    background-color: #eee;
}

.product-page-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.add-to-cart-btn{
    background-color: #044aab;
    border: none;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    font-family: 'Hedvig Letters Serif', serif;
}


.auth-form {
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.auth-form h2 {
    text-align: center;
}

.auth-form form {
    display: flex;
    flex-direction: column;
}

.auth-form input {
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.auth-form button {
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.auth-form button:hover {
    background-color: #0056b3;
}


.register-link {
    margin-top: 15px;
    text-align: center;
}

.register-link a {
    color: #007bff;
    text-decoration: none;
}

.register-link a:hover {
    text-decoration: underline;
}

.error {
    color: red;
    font-size: 0.7em;
    margin:0;
    padding: 2px 10px
}

.order-thumbnail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.order-thumbnail:hover {
    background-color: #f5f5f5; /* Light grey background on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for a "lifted" effect */
    cursor: pointer; /* Change cursor to indicate clickability */
}

.order-detail {
    flex-basis: 20%; /* Adjust the width of each detail as needed */
    text-align: center;
}

.my-account {
    display: flex;
    min-height: 60vh;
}

.my-account-sidebar {
    width: 250px;
    border-right: 1px solid #ddd;
    flex-direction: column;
}

.sidebar-link,
.sidebar-logout {
    display: block;
    padding: 10px;
    width: 250px;
    text-decoration: none;
    color: black;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-family: 'Hedvig Letters Serif', serif;;
}

.sidebar-link:hover, .sidebar-link.active {
    background-color: #f0f0f0;
}

.sidebar-logout:hover {
    background-color: #ee1010;
    color:#f0f0f0;
}

.my-account-content {
    flex-grow: 1;
    padding: 20px;
}

.cart-thumbnail {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
}

.cart-image-container{
    height: 100%;
    width: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
}

.cart-image {
    width: auto;
    height: auto;
    max-width: 100%; /* Maximum width is the full container width */
    max-height: 100%; /* Maximum height is the full container height */
    object-fit: contain; 
    border-radius: 4px;
}

.cart-detail {
    margin-right: 20px;
    flex-grow: 1;
}

.cart-remove{
    background-color: #ff6347; /* Tomato color for delete button */
    color: white;
    border: none;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.cart-remove:hover {
    background-color: #e53e3e; /* Darker shade for hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.cart-detail.cart-remove:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 99, 71, 0.5); /* Tomato color with opacity for focus */
}

.quantity-input {
    width: 60px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.cart_summary_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.cart_button {
    background-color: #044aab;
    border: none;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    font-family: 'Hedvig Letters Serif', serif;
}


.about-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 10px 70px;
    line-height: 1.6;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-page h1 {
    // color: #007bff;
    text-align: center;
    margin-bottom: 20px;
}

.about-page p {
    margin-bottom: 15px;
    text-align: justify;
}

.about_image {
    width: 300px;
    height: auto;
    object-fit: cover;
    align-self: center;
}


.product-thumbnail-image {
    width: auto;
    height: auto;
    max-width: 100%; /* Maximum width is the full container width */
    max-height: 100%; /* Maximum height is the full container height */
    object-fit: contain; 
    margin-bottom: 10px;
    border-radius: 4px;
}


.confirmation-address-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0 15px;
}

.address-form input,
.additional-notes {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Hedvig Letters Serif', serif;
    max-width: 600px;
    box-sizing:border-box
}

.additional-notes {
    height: 120px;
    resize: vertical; /* Allows the user to resize the textarea vertically */
}

.order-confirmation {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
}

.address-form {
    margin-top: 20px;
    width: 100%;
}

.address-form label {
    display: block;
    margin-bottom: 5px;
}

.confirm-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.confirm-button:hover:not(:disabled) {
    background-color: #0056b3;
}

.order-info-paragraph {
    margin-bottom: 10px;
    font-size: 0.8rem;
    line-height: 1.5;
    color:#cdcdcd
}

.additional-notes {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Hedvig Letters Serif', serif;
    resize: vertical; /* Allows the user to resize the textarea vertically */
}

.confirm-checkbox {
    margin-bottom: 10px;
}

.confirm-checkbox input[type="checkbox"] {
    margin-right: 5px;
    font-family: 'Hedvig Letters Serif', serif;
}

.confirm-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

.tradeshow-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    min-height: 60vh;
}

.tradeshow-list {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
}

.tradeshow-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    width: 100%;
}

.tradeshow-item img {
    width: 400px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    object-fit: fit;
    margin-right: 20px;
}

.tradeshow-info p {
    margin: 0;
    font-size: 16px;
}


.order-detail {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.order-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.order-address p{
    margin: 0;
    text-align:  left;
}

.order-status p {
    margin: 0;
}

.order-items .order-item {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
}

.order-total {
    font-weight: bold;
    text-align: right;
    margin-top: 20px;
}

.cancel-order-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

.cancel-order-button:hover {
    background-color: #cc0000;
}

.combo-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.combo-page h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.combo-page p {
    font-size: 16px;
    margin-bottom: 20px;
}

.combo-page img {
    max-width: 100%;
    width: 90%;
    height: auto;
    margin-bottom: 20px;
}

.combo-selection {
    margin-bottom: 20px;
}

.combo-selection select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
  
  
@media screen and (max-width: 960px){
    .home_title.open {
        transform: translate(-50%, 80%);
        
    }
}

